import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql, navigate } from "gatsby"
import "../../../graphql/fragments"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import { useMediaPredicate } from "react-media-hook"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import { CoverMain } from "../../../components/cover-main/cover-main"
import { Section, Container } from "../../../components/grid/grid"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const setCookie = ({cname, cvalue, exdays}) => {
    var d = new Date()
    exdays = exdays || 30
    d.setTime(d.getTime() + (exdays*24*60*60*1000))
    var expires = "expires="+ d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  }
  
  const getCookie = (cname) => {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }
  
  const checkCookie = () => {
    let cname = getCookie("name")
    let cemail = getCookie("email")
    let cphoneNumber = getCookie("phoneNumber")
    if (cname != "" && cemail != "" && cphoneNumber != "") {
      console.log("data has an entered")
    } else {
      navigate("/promo-karyawan-sinarmas-group")
    }
  }

const FaqPromoKaryawanDetails = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query{
      imgCoverMain: file(relativePath: { eq: "imgBannerDevelpoment.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1125) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        page(id: "/promo-karyawan-sinarmas-group/details", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          faqContentPromo {
            faqContentPromo
          }
          blocks {
            name
            ... on WordPress_CoreHeadingBlock {
              attributes {
                content
              }
            }
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
            ... on WordPress_CoreParagraphBlock {
              attributes {
                ... on WordPress_CoreParagraphBlockAttributesV3 {
                  content
                }
              }
            }
          }
          translation(language: ID) {
            title
            blocks {
              name
              ... on WordPress_CoreHeadingBlock {
                attributes {
                  content
                }
              }
              ... on WordPress_CoreCoverBlock {
                attributes {
                  url
                  className
                }
              }
              ... on WordPress_CoreParagraphBlock {
                attributes {
                  ... on WordPress_CoreParagraphBlockAttributesV3 {
                    content
                  }
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

//   const [formValues, setFormValues] = useState({
//     name: "",
//     email: "",
//     phoneNumber: "",
//   })

  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const seo = staticData?.wordPress?.page?.seo
  const wordPress = staticData?.wordPress?.page
  let coverItems = []

  // eslint-disable-next-line
  wordPress && wordPress?.blocks?.map(item => {
    if(item.name === "core/cover"){
      coverItems.push({url: item?.attributes?.url, className: item?.attributes?.className})
    }
  })

  const featuredImage = staticData?.wordPress?.page?.featuredImage?.mediaItemUrl

  useEffect(() => {
    // eslint-disable-next-line
    wordPress && wordPress?.blocks?.map(item => {
      if(item.name === "core/cover"){
        coverItems.push({url: item?.attributes?.url, className: item?.attributes?.className})
      }
    })

    if(wordPress?.faqContentPromo?.faqContentPromo){
      const thisTable = document.querySelectorAll("table")
      const thisThead = document.querySelectorAll("thead")
      const thisTbody = document.querySelectorAll("tbody")
      for (let i = 0; i < thisTable.length; i++) {
        thisTable[i].setAttribute("class", "table table-bordered")
        thisTable[i].style.border = "1px solid #e5e5e5"
      }
      for (let i = 0; i < thisThead.length; i++) {
        thisThead[i].style.whiteSpace = "nowrap"
      }
      for (let i = 0; i < thisTbody.length; i++) {
        thisTbody[i].style.whiteSpace = "nowrap"
      }
    }

    if(!resizeScreen){
      const thisCover = document.querySelector(".cover-main.cover-md")
      thisCover.style.height = `428px !important`
      document.getElementsByClassName("cover-main")[0].style.backgroundSize = `contain!important`
    }
  }, [])

  useEffect(() => {
    checkCookie()
  }, [])

  return(
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        url={location?.href}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        img={featuredImage}
        fbImg={featuredImage}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <CoverMain
        h1={""}
        img={
          resizeScreen 
            ? coverItems[1]?.url
            : coverItems[0]?.url
          }
        alt={wordPress?.title}
        className={`cover-md ${resizeScreen ? coverItems[1]?.className : coverItems[0]?.className}`}
      />
      <Section className="promo-content">
        {wordPress?.faqContentPromo?.faqContentPromo && (
          <div className="container" dangerouslySetInnerHTML={{__html: wordPress?.faqContentPromo?.faqContentPromo }}></div>
        )}
      </Section>
      {/* {wordPress?.blocks.map((item, i) => {
        switch (item.name) {
          case "core/heading":
            return (
              <div key={`heading-${i}`} className="container container-md">
                <div className={i === 0 ? "py-main pb-3 pt-4" : "pt-main"}>
                  <HeadingBasic h2={item.attributes.content} />
                </div>
              </div>
            )
          case "core/paragraph":
            return (
              <div
                key={`headingbasic-${i}`}
                className={`py-main pb-3 pt-3`}
              >
                <div className="container container-md">
                  <HeadingBasic text={[item.attributes.content]} />
                </div>
              </div>
            )
          default:
            return <></>
        }
      })} */}
      <Section className="main-faq-promo-karyawan">
        <Container>
          <Accordion preExpanded={[1]} allowZeroExpanded>
            <AccordionItem uuid={1}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  FAQ Promo Karyawan Sinarmas Group
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Accordion allowZeroExpanded>
                  <AccordionItem id="aci1">
                    <AccordionItemHeading id="ach1">
                      <AccordionItemButton>
                        1. Apakah yang dimaksud dengan Program KPR Khusus Karyawan Sinar Mas Group?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Program ini adalah program kepemilikan properti untuk karyawan Sinar Mas Group yang diadakan oleh Sinar Mas Land bekerjasama dengan bank partner, untuk memberikan kemudahan dan keringanan bagi karyawan Sinar Mas Group yang membutuhkan properti melalui program KPR.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci2">
                    <AccordionItemHeading id="ach2">
                      <AccordionItemButton>
                        2. Apa saja perusahaan Sinar Mas Group yang termasuk dalam program ini?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Semua anak perusahaan Sinar Mas Group, termasuk perusahaan joint venture dapat berpartisipasi dalam program ini.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci3">
                    <AccordionItemHeading id="ach3">
                      <AccordionItemButton>
                        3. Siapa yang bisa mengajukan program KPR Khusus Karyawan Sinar Mas ini?  
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Semua karyawan Sinar Mas Group berhak untuk mengajukan program KPR Khusus Karyawan Sinar Mas ini 
                        <i>*) Syarat & ketentuan berlaku</i>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci4">
                    <AccordionItemHeading id="ach4">
                      <AccordionItemButton>
                        4. Apakah ada persyaratan masa kerja dan ketentuan ikatan kerja untuk mengikuti program ini?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Ada persyaratan masa kerja dan ketentuan ikatan kerja untuk mengikuti program ini, sesuai syarat dan ketentuan yang ditetapkan oleh bank yang bekerjasama.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci5">
                    <AccordionItemHeading id="ach5">
                      <AccordionItemButton>
                        5. Kapan periode promo KPR Khusus Karyawan Sinar Mas? 
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Promo berlaku sampai dengan 31 Desember 2022*.
                        <i>*) Syarat & ketentuan berlaku</i>
                      </p>                      
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci6">
                    <AccordionItemHeading id="ach6">
                      <AccordionItemButton>
                        6. Apa saja keringanan yang ditawarkan dalam program ini?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          Free DP*
                        </li>
                        <li>
                          BUNGA KPR mulai dari 3,65%*
                        </li>
                        <li>
                          TENOR s.d. 20 tahun*
                        </li>
                        <li>
                          CICILAN s.d. 70% dari income*
                        </li>
                        <li>
                          FREE Biaya Admin & Provisi*
                        </li>
                        <li>
                          Bisa Bayar BUNGANYA AJA dulu!*
                        </li>
                      </ul>
                      <p>
                        <i>*) Syarat & ketentuan berlaku</i>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci7">
                    <AccordionItemHeading id="ach7">
                      <AccordionItemButton>
                        7. Proyek apa saja yang ditawarkan dalam Program KPR Sinar Mas ini? Dan di mana lokasinya?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <ul>
                        <li>
                          Program ini berlaku untuk semua proyek properti Sinar Mas Land, kecuali proyek-proyek JV (Joint Venture)*
                        </li>
                        <li>
                          Lokasi proyek: BSD City, Tangerang, Jakarta, Cibubur, Surabaya, Batam, Balikpapan
                        </li>
                        <li>
                          Proyek Joint Venture (TIDAK termasuk dalam program) : Nava Park, The Zora, Upper West, Akasa, Grand Wisata, Kota Deltamas
                        </li>
                      </ul>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci8">
                    <AccordionItemHeading id="ach8">
                      <AccordionItemButton>
                        8. Jenis produk apa saja yang ditawarkan dalam program KPR Sinar Mas?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Program ini berlaku untuk pembelian rumah, apartemen, ruko (Non kavling) sesuai kebutuhan dari karyawan.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci9">
                    <AccordionItemHeading id="ach9">
                      <AccordionItemButton>
                        9. Bagaimana cara mendapatkan keringanan untuk program KPR Sinar Mas?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>Silakan menghubungi (021) 5315 9000, atau dapat langsung menghubungi contact number di bawah ini untuk informasi lebih lanjut.</p><br/><br/>
                      <p>Informasi Proyek</p>
                      <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Proyek</th>
                            <th>Contact Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Residential BSD</a>
                            </td>
                            <td>
                              <a href="tel:085712359000">0857 1235 9000</a>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Residential Non-BSD</a>
                            </td>
                            <td>
                              <a href="tel:08128315050">0812 831 5050</a> <span>(kota wisata)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Commercial</a>
                              </td>
                            <td>
                              <a href="tel:08159137981">0815 9137 981</a> <span>(Ibu Andriani Otang)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Apartemen Jakarta</a>
                            </td>
                            <td>
                              <span>Southgate: </span><a href="tel:081280488585">0812 8048 8585</a><span> (Ibu Etik)</span><br />
                              <span>The Elements: </span><a href="tel:085655232288">0856 5523 2288</a><span> (Ibu Mayo)</span><br />
                              <span>Aerium: </span><a href="tel:08128299903">0812 8299 903</a><span> (Bapak Hendra)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Surabaya</a>
                            </td>
                            <td>
                              <a href="tel:081389990780">0813 8999 0780</a> <span>(Hot Line)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Balikpapan</a>
                            </td>
                            <td>
                              <a href="tel:082157891961">0821 5789 1961</a> <span>(Budi Widiyanto)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Batam</a>
                            </td>
                            <td>
                              <a href="tel:08117008238">0811 7008 238</a> <span>(CS Nuvasa Bay)</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <br/>
                      <br/>
                      <p>Informasi Bank</p>
                      <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Bank Partner</th>
                            <th>Contact Number</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Panin Bank</a>
                            </td>
                            <td>
                              <a href="tel:087808777037">0878 0877 7037</a> <span>(Bapak Pandu)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">CIMB Niaga</a>
                            </td>
                            <td>
                              <a href="tel:08119862722">0811 9862 722</a> <span>(Ibu Kirana)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Permata Bank</a>
                            </td>
                            <td>
                              <a href="tel:083821881441">0838 2188 1441</a> <span>(Bapak Kelvin)</span><br />
                              <a href="tel:089647634099">0896 4763 4099</a> <span>(Ibu Jessica)</span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-bold">
                              <a href="#">Bank Sinar Mas</a>
                            </td>
                            <td>
                              <a href="tel:08773733538">0877 3733 538</a> <span>(Bapak Wahyu)</span><br />
                              <a href="tel:0816742752">0816 742 752</a> <span>(Bapak Anwar)</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci10">
                    <AccordionItemHeading id="ach10">
                      <AccordionItemButton>
                        10. Siapa saja Bank yang sudah bekerjasama dalam program ini?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Permata Bank, CIMB Niaga, Panin Bank, dan Bank Sinar Mas.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci11">
                    <AccordionItemHeading id="ach11">
                      <AccordionItemButton>
                        11. Apakah program yang diberikan berlaku sama untuk semua bank partner?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Masing-masing bank memiliki benefit khusus untuk karyawan Sinar Mas Group dengan syarat dan kondisi yang berbeda.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem id="aci12">
                    <AccordionItemHeading id="ach12">
                      <AccordionItemButton>
                        12. Apakah promo produk yang sudah ada sebelumnya tetap berlaku pada program ini?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Promo produk tetap mengacu pada skema promo yang sudah berlaku sesuai syarat dan ketentuan yang sudah ditetapkan. Jadi program ini hanya untuk memberikan kemudahan bagi yang memilih pembiayaan KPR.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </Container>
      </Section>
    </Layout>
  )
}
export default FaqPromoKaryawanDetails